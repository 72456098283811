import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import RichText from '../atoms/richtext';
import { media } from '../../utils/media-queries';
import Arrow from '../atoms/arrow';

const Position = ({ item }) => {
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (hover)
      setTimeout(() => {
        setHover(false);
      }, 500);
  });

  return (
    <div
      className="position"
      onMouseEnter={() =>
        setTimeout(() => {
          setHover(true);
        }, 150)
      }
    >
      <div className="position-wrapper">
        <Arrow className="arrow" active={hover} />
        <p className="name">{item.name}</p>
      </div>
      <p className="location">{item.position}</p>
    </div>
  );
};

const Join = ({
  headline,
  positions,
  last = false,
  description,
  className,
}) => (
  <>
    <StyledJoin className={className}>
      <div className="wrapper grid">
        <h2>{headline}</h2>
        <div className="desc">
          <RichText blocks={description} />
        </div>
        {positions.length ? (
          <div className="positions">
            {positions.map((item, i) => (
              <a
                href={item.url}
                target="_blank"
                rel="noopener noreferrer"
                key={i}
              >
                <Position key={`position-${i}`} item={item} />
              </a>
            ))}
          </div>
        ) : (
          <span />
        )}
      </div>
    </StyledJoin>
    {!last && <StyledDivide className="divide" />}
  </>
);

const StyledJoin = styled.section`
  padding-top: var(--sp-1-3);
  padding-bottom: var(--sp-1-3);
  margin-left: var(--sidebar-width);
  margin-right: var(--sp-21);
  background: var(--red);
  color: var(--white);
  width: calc(100% - var(--sp-1-8));
  height: 100%;
  min-height: 400px;

  .wrapper {
    margin-left: var(--sidebar-width);
    padding-left: var(--sidebar-width);
    grid-gap: 0;
  }

  &.home {
    margin-left: 0;
    margin-top: var(--sp-13);
    padding-top: var(--sp-3-8);

    h2,
    .desc,
    .position {
      padding-left: 0;
    }

    h2 {
      font-size: var(--fs-hl);
    }

    .positions {
      width: 90%;
    }
  }

  .desc {
    padding-bottom: var(--sp-5);
    width: 80%;

    p {
      margin:2rem 0;
    }
  }

  .positions {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-bottom: var(--sp-5);
    width: 85%;
  }

  .position {
    position: relative;
    cursor: pointer;
    // display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: var(--sp-1-3);
    padding-bottom: var(--sp-1-3);
    justify-content: space-between;

    &:hover {
      p {
        opacity: 50%;
      }
    }

    p {
      transition: 0.15s;
      min-width: 20%;
    }

    // .name {
    //   margin-left: 8rem;
    // }

    .location {
      font-size: var(--fs-small);
      padding-bottom: 1rem;
    }

    .position-wrapper {
      position:relative;
    }

    .arrow {
      left:auto;
      right:0;
    }
  }

  h2 {
    font-size: var(--fs-subhl);
    font-weight: 300;
    padding-bottom: var(--sp-1-3);
  }

  @media ${media.M} {
    width: 66vw;
    margin-bottom: var(--sp-13);

    .position {
      justify-content: flex-start;

      // .name {
      //   margin-left: 11rem;
      // }
    }

    .wrapper {
      margin-left: var(--sidebar-width-at-m);
      padding-left: var(--sidebar-width-at-m);
    }

    &.home {
      margin-bottom: 0;
      padding-bottom: var(--sp-8);
      width: 75vw;
      margin-top: var(--sp-21);

      .wrapper {
        * {
          grid-column: 2 / 12;
        }
      }
    }
  }

  @media ${media.XL} {
    .desc {
      width: 40%;
    }
  }
`;

const StyledDivide = styled.div`
  width: 100%;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  margin-top: var(--sp-3);

  @media ${media.M} {
    margin-top: var(--sp-13);
    margin-left: 0;
  }
`;

Join.propTypes = {
  headline: PropTypes.string,
  last: PropTypes.bool,
  positions: PropTypes.array,
  description: PropTypes.array,
  className: PropTypes.string,
};

Position.propTypes = {
  item: PropTypes.object,
};

export default Join;
