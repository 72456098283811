import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { GatsbyImage } from 'gatsby-plugin-image';
import Tag from '../atoms/tag';
import { media } from '../../utils/media-queries';
import { convertTypes } from '../../utils/helper';
import Arrow from '../atoms/arrow';
import { TLink } from '../atoms/tlink';

const NewsPreview = ({
  type,
  description,
  date,
  time,
  slug,
  src,
  alt,
  className,
  logo,
  newsPage = false,
}) => {
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (hover)
      setTimeout(() => {
        setHover(false);
      }, 300);
  });

  return (
    <StyledNewsPreview
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={className}
      onMouseEnter={() =>
        setTimeout(() => {
          setHover(true);
        }, 0)
      }
    >
      <div className={`wrapper ${type}`}>
        {type !== 'news' && type !== 'interview' ? (
          <a href={slug} target="_blank" rel="noopener noreferrer">
            <figure
              className={logo ? 'logo' : ''}
              style={{
                background:
                  type === 'conference' ? 'var(--red-mid)' : 'var(--red-light)',
              }}
            >
              <GatsbyImage width="200" image={src} alt={alt} />
            </figure>
            <div className="text">
              <p className="type">{convertTypes(type)}</p>
              <h3>{description}</h3>
              <div className="tags">
                <Tag text={date} />
                <Arrow active={hover} className="news" />
              </div>
            </div>
          </a>
        ) : (
          <TLink to={`/${type}/${slug}`} duration={newsPage ? 600 : 600}>
            <figure>
              <GatsbyImage width="200" image={src} alt={alt} />
            </figure>
            <div className="text">
              <p className="type">{convertTypes(type)}</p>
              <h3>{description}</h3>
              <div className="tags">
                <Tag text={date} />
                <Tag text={time} />
              </div>
            </div>
          </TLink>
        )}
      </div>
    </StyledNewsPreview>
  );
};

const StyledNewsPreview = styled(motion.div)`
  padding-right: var(--sp-1-8);
  padding-left: var(--sp-1-8);
  padding-bottom: var(--sp-3);
  width: 100%;

  &:hover {
    figure {
      .gatsby-image-wrapper {
        transform: scale(1.05);
      }
    }
  }

  figure.logo {
  }

  .wrapper {
    background: var(--white);

    &.interview {
      background: var(--red-light);
    }
  }

  figure {
    overflow: hidden;
    position:relative;
 
    .gatsby-image-wrapper {
      position:relative;
      height: 0;
      padding-bottom: 75%;
  
      transition: 0.4s ease-out;
      vertical-align: top;

      img {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        object-fit:cover;
        object-position:center;
      }
    }

    &.logo {
      display: flex;
      justify-content: center;
      align-items: center;

      .gatsby-image-wrapper {
        max-width: 80%;
        height: auto;
        margin: 0 auto;
      }
    }
  }

  .text {
    padding: var(--sp-1);

    .type {
      font-size: 1.2rem;
      color: var(--red);
      padding-bottom: 0.8rem;
      padding-top: var(--sp-0);
      font-weight: bold;
    }

    h3 {
      font-size: 1.4rem;
      padding-bottom: var(--sp-3);
    }
  }

  .tags {
    display: flex;
    align-items: flex-start;
    font-weight: bold;
  }

  @media ${media.M} {
    padding-left: 0;
    padding-right: var(--sp-3);
    width: calc(100% / 3);

    &.landscape {
      width: calc((100% / 3) * 2);
      margin-left: calc(100% / 3);

      .wrapper {
        a {
          display: flex;

          figure {
            min-width: calc(100% / 2 - 1.5rem);
            max-width: calc(100% / 2 - 1.5rem);
            height: auto;
          }

          .text {
            padding: var(--sp-8) var(--sp-5);
          }
        }
      }
    }

    // figure {
    //   height: 20vw;
    // }

    .text {
      padding: var(--sp-1) var(--sp-3);

      h3 {
        overflow: hidden;
        min-height: calc((var(--fs-copy)) * 4.3);
        max-height: calc((var(--fs-copy)) * 3);
      }
    }
  }
`;

NewsPreview.propTypes = {
  type: PropTypes.string,
  description: PropTypes.string,
  date: PropTypes.string,
  time: PropTypes.string,
  slug: PropTypes.string,
  src: PropTypes.object,
  alt: PropTypes.string,
  className: PropTypes.string,
  logo: PropTypes.bool,
  newsPage: PropTypes.bool,
};

export default NewsPreview;
