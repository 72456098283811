import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { motion, useTransform, useViewportScroll } from 'framer-motion';
import Logo from '../../../assets/svgs/DraupnirBio_Logo_White_RGB.svg';
import { media } from '../../../utils/media-queries';
import ArrowSvg from '../../../assets/svgs/arrow.svg';

const Intro = ({ headline }) => {
  const { scrollYProgress } = useViewportScroll();
  const opacity = useTransform(scrollYProgress, [0, 0.1], [1, 0]);

  return (
    <StyledIntro className="intro">
      <div className="logo">
        <Logo />
      </div>
      <h1>{headline}</h1>
      <motion.div style={{ opacity }} className="scroll">
        <ArrowSvg /> <span>Scroll down</span>
      </motion.div>
    </StyledIntro>
  );
};

const StyledIntro = styled.section`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--white);
  position: relative;

  .scroll {
    position: absolute;
    bottom: var(--sp-13);
    left: 0;
    width: 100%;
    text-align: center;
    font-size: var(--fs-small);
    z-index: 10;
    animation: down 3.5s ease-out 0s infinite;

    svg {
      width:1rem;
      transform: rotate(135deg);
      margin-right:1.5rem;
    }

    g {
      stroke: var(--white);
    }
  }

  h1 {
    text-align: center;
    width: 80%;
    font-size: var(--fs-logo);
  }

  .logo {
    width: 75vw;
    padding-bottom: 24px;
  }

  h1,
  .logo {
    transform: translateY(-50px);
  }

  @keyframes down {
    0% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(7px);
    }
    60% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(0);
    }
  }

  @media ${media.M} {
    margin-left: var(--sidebar-width-at-m);
    margin-right: calc(var(--sp-1-8) - var(--sp-3));

    .scroll {
      bottom: calc(var(--sp-1-8) / 2);
      right: var(--sidebar-width-at-m);
      left:auto;
      padding-left:3.1rem;
      text-align: left;
    }

    h1 {
      width: 100%;
    }

    .logo {
      width: 50vw;
      padding-bottom: var(--sp-5);
    }

    h1,
    .logo {
      transform: translateY(0);
    }
  }
`;

Intro.propTypes = {
  headline: PropTypes.string,
};

export default Intro;
