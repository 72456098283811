import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CtaPage } from '../../atoms/cta';
import RichText from '../../atoms/richtext';
import { media } from '../../../utils/media-queries';

const About = ({ headline, copy, title, to }) => (
  <StyledAbout className="grid">
    <div className="text">
      <h2 className="hl" dangerouslySetInnerHTML={{ __html: headline }} />
      <div className="copy">
        <RichText blocks={copy} />
      </div>
      <CtaPage title={title} to={to.page} light />
    </div>
  </StyledAbout>
);

const StyledAbout = styled.section`
  margin-right: var(--sp-1-3);
  margin-left: var(--sidebar-width);
  color: var(--white);
  position: relative;
  padding-bottom: var(--sp-13);

  .text {
    background: var(--red);
    padding: var(--sp-5) var(--sp-1);
    margin-right: -1.5rem;

    .copy {
      padding-top: var(--sp-1-3);
      padding-bottom: var(--sp-1);
    
      @media ${media.M} {
        font-size:1.9rem;
      }
    }
  }

  button {
    width: auto;
  }

  @media ${media.M} {
    padding-bottom: var(--sp-13);
    transform: translateY(calc(var(--sp-8) * -1));
    padding-bottom: var(--sp-8);
    margin-left: var(--sidebar-width-at-m);

    .text {
      grid-column: 5 / 13;
      padding: var(--sp-13) var(--sp-13);
      margin-right: -10rem;
    }
  }

  @media ${media.XL} {
    .text {
      grid-column: 5 / 13;

      .hl,
      .copy {
        width: 80%;
      }
    }
  }
`;

About.propTypes = {
  headline: PropTypes.string,
  copy: PropTypes.array,
  title: PropTypes.string,
  to: PropTypes.object,
};

export default About;
