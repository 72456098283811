import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { CtaPage } from '../../atoms/cta';
import { GatsbyImage } from 'gatsby-plugin-image';
import RichText from '../../atoms/richtext';
import { media } from '../../../utils/media-queries';

const Description = ({ headline, copy, title, to, videoSrc, imageSrc }) => (
  <>
  {headline || copy || videoSrc || imageSrc  && 
  <StyledDescription>
      <div className="wrapper grid">
        <div className="text">
          <h2 className="hl">{headline}</h2>
          <div className="copy">
            <RichText blocks={copy} />
          </div>
          {to && title && 
            <CtaPage title={title} to={to.page} light />
          }
        </div>
        <figure>
          {videoSrc && <video src={videoSrc} loop autoPlay playsInline muted poster={imageSrc} />}
          {!videoSrc && <GatsbyImage
            image={imageSrc?.asset.gatsbyImageData}
            alt={imageSrc?.alt}
          />}
        </figure>
      </div>
      </StyledDescription>
    }
    </>
);

const StyledDescription = styled.section`
  color: var(--white);
  margin-bottom: var(--sp-8);
  padding: var(--sp-1-8) 0;
  background: var(--red);

  .wrapper {
    margin-right: var(--sp-1-8);
    margin-left: calc(var(--sidebar-width) * 2);
  }

  .text {
    padding: var(--sp-3) 0;

    .copy {
      padding-top: var(--sp-1-3);
      padding-bottom: var(--sp-1);
    
      @media ${media.M} {
        font-size:1.9rem;
      }
    }
  }

  button {
    margin-top: var(--sp-1);
    width: auto;
  }

  figure {
    padding-bottom: var(--sp-3);
    margin-left: calc((var(--sp-1-8) * 2) / -2);

    video {
      width: 100%;
    }
  }

  @media ${media.M} {
    margin-bottom: var(--sp-13);

    .wrapper {
      margin-left: var(--sidebar-width-at-m);
      margin-right: var(--sidebar-width-at-m);
    }

    .text {
      grid-column: 2 / 10;
      margin-bottom: var(--sp-3);
    }

    figure {
      margin-left: 0;
    }
  }

  @media ${media.XL} {
    .text {
      grid-column: 2 / 8;
    }
  }
`;

Description.propTypes = {
  headline: PropTypes.string,
  copy: PropTypes.array,
  title: PropTypes.string,
  to: PropTypes.object,
};

export default Description;
