import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Arrow from './arrow';

export const CtaPage = ({ title, to, light }) => (
  <StyledCta type="button" light={light}>
    <Link className="cta" to={`/${to}`}>
      {title}
    </Link>
  </StyledCta>
);

export const CtaLink = ({ title, href, light }) => {
  const [active, setActive] = useState(false);

  return (
    <StyledLink
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
    >
      <Arrow className="arrow link" active={active} />
      <div className="alink">
        <a href={href} target="_blank" rel="noopener noreferrer">
          {title}
        </a>
      </div>
    </StyledLink>
  );
};

const StyledCta = styled.button`
  margin-top: var(--sp-2);
  color: ${(props) => (props.light ? 'var(--white)' : 'var(--red)')};
  border: 1px solid ${(props) => (props.light ? 'var(--white)' : 'var(--red)')};
  font-size: var(--fs-small);
  padding: var(--sp-0) var(--sp-3);
  border-radius: 120px;
  transition: 0.3s;
  background: transparent;

  &:hover {
    border-color: ${(props) => (props.light ? 'var(--white)' : 'var(--red)')};
    background: ${(props) => (props.light ? 'var(--white)' : 'var(--red)')};
    color: ${(props) => (props.light ? 'var(--red)' : 'var(--white)')};
  }
`;

const StyledLink = styled.button`
  text-align: left;
  color: var(--red);
  text-decoration: underline;
  display: flex;
  padding-top: var(--sp-1);

  .arrow {
    position: static;
    display: inline-block;
  }

  .alink {
    display: inline-block;
    margin-left: var(--sp-2);
  }
`;

CtaPage.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
  light: PropTypes.bool,
};

CtaLink.propTypes = {
  title: PropTypes.string,
  href: PropTypes.string,
  light: PropTypes.string,
};
