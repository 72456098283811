export const convertTypes = (type) => {
  switch (type) {
    case 'conference':
      return 'Conference';
    case 'event':
      return 'Event';
    case 'news':
      return 'News';
    case 'meeting':
      return 'Meeting';
    case 'article':
      return 'Article';
    default:
      return 'News';
  }
};

export const clamp = (num, min, max) =>
  num <= min ? min : num >= max ? max : num;

export const lerp = (value1, value2, amount) => {
  amount = amount < 0 ? 0 : amount;
  amount = amount > 1 ? 1 : amount;
  return value1 + (value2 - value1) * amount;
};
