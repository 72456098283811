import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { CtaPage } from '../atoms/cta';
import NewsPreview from '../molecules/newsPreview';
import { media } from '../../utils/media-queries';

const NewsSection = () => {
  const { news } = useStaticQuery(graphql`
    query Articles {
      news: allSanityNews {
        nodes {
          articles {
            headline
            type {
              type
            }
            logo
            slug
            time
            date
            alt
            mainImage {
              asset {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  const { articles } = news.nodes[0];

  return (
    <>
      <StyledNews>
        {articles.slice(0, 7).map((article, i) => (

          <NewsPreview
            key={i}
            slug={article.slug}
            type={article.type.type}
            description={article.headline}
            date={article.date}
            time={article.time}
            logo={article.logo}
            src={article.mainImage?.asset.gatsbyImageData}
            alt={article.alt}
            // className={i === 3 ? 'landscape' : ''}
          />

        ))}
      </StyledNews>
      {articles.length > 3 &&
        <div className="btn-wrapper">
          <CtaPage title="See more news" to="news" light />
        </div>
      }
    </>
  );
};

const StyledNews = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media ${media.M} {
    margin-left: var(--sidebar-width-at-m);
    margin-right: calc(var(--sidebar-width-at-m) - var(--sp-1-8));
  }
`;

export default NewsSection;
