import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import ArrowSvg from '../../assets/svgs/arrow.svg';
import { media } from '../../utils/media-queries';

const Arrow = ({ active, className }) => (
  <StyledArrow className={className}>
    <div className="arrow-wrapper">
      <motion.span
        animate={{
          x: active ? [-30, 0, 0, 0] : [0, 0, 0, 0],
          y: active ? [30, 0, 0, 0] : [0, 0, 0, 0],
        }}
      >
        <ArrowSvg />
      </motion.span>
      <motion.span
        animate={{
          x: active ? [0, 30, 30, 30] : [0, 0, 0, 0],
          y: active ? [0, -30, -30, -30] : [0, 0, 0, 0],
        }}
      >
        <ArrowSvg />
      </motion.span>
    </div>
  </StyledArrow>
);

const StyledArrow = styled.div`
  cursor: pointer;
  position: absolute;
  left: 0;

  &.news {
    position: static;
    transform: scale(0.6) translate3d(-10px, -10px, 0);

    .arrow-wrapper {
      background: var(--red);
    }

    path {
      stroke: var(--white);
    }
  }

  &.link {
    .arrow-wrapper {
      background: var(--red);
    }

    path {
      stroke: var(--white);
    }
  }

  .arrow-wrapper {
    background: var(--white);
    border-radius: 50%;
    height: 26px;
    width: 26px;
    align-items: center;
    transition: 0.3s;
    overflow: hidden;
    position: relative;

    span {
      position: absolute;
      display: inline-block;
      padding: 0 7px;
    }
  }

  @media ${media.M} {
    .arrow-wrapper {
      height: 32px;
      width: 32px;

      span {
        padding: 0 10px;
      }
    }
  }

  @media ${media.XL} {
    .arrow-wrapper svg {
      transform:translateY(-3px) !important;
    }
  }
`;

Arrow.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
};

export default Arrow;
