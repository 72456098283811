import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SEO from '../components/organisms/seo';
import About from '../components/sections/frontpage/about';
import Description from '../components/sections/frontpage/description';
import News from '../components/sections/frontpage/news';
import Intro from '../components/sections/frontpage/intro';
import Join from '../components/modules/join';

const IndexPage = ({ data }) => {
  const {
    headline,
    aboutIntro,
    descriptionIntro,
    content,
  } = data.home.nodes[0];

  return (
    <>
      <SEO />
      <Intro headline={headline} />
      <About
        headline={aboutIntro.headline}
        copy={aboutIntro._rawCopy}
        title={aboutIntro.linkCopy}
        to={aboutIntro.page}
      />
      <Description
        headline={descriptionIntro.headline}
        copy={descriptionIntro._rawCopy}
        title={descriptionIntro.linkCopy}
        to={descriptionIntro.page}
        videoSrc={descriptionIntro.video}
        imageSrc={descriptionIntro.image}
      />
      <News />
      <Join
        className="home"
        headline={content[0].headline}
        positions={content[0].positions}
        description={content[0]._rawDescription}
        url={content[0].url}
        last
      />
    </>
  );
};

export const query = graphql`
  query Home {
    home: allSanityHome {
      nodes {
        headline
        aboutIntro {
          headline
          _rawCopy
          linkCopy
          page {
            page
          }
        }
        descriptionIntro {
          headline
          _rawCopy
          video
          image {
            asset {
              gatsbyImageData
            }
          }
          linkCopy
          page {
            page
          }
        }
        content {
          headline
          _rawDescription
          positions {
            name
            url
            position
          }
        }
      }
    }
  }
`;

IndexPage.propTypes = {
  data: PropTypes.object,
};

export default IndexPage;
