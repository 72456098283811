import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Tag = ({ text }) => <StyledTag>{text}</StyledTag>;

const StyledTag = styled.p`
  background: var(--red-light);
  padding: 0.3rem var(--sp-0);
  border-radius: var(--sp-2);
  font-size: 1rem;
  color: var(--red);
  margin-right: var(--sp-0);
  // margin-bottom: var(--sp-1);
`;

Tag.propTypes = {
  text: PropTypes.string,
};

export default Tag;
