import React from 'react';
import styled from 'styled-components';
import { CtaPage } from '../../atoms/cta';
import NewsSection from '../../organisms/newsSection';
import { media } from '../../../utils/media-queries';

const News = () => (

  <StyledNews>
    <p className="hl">Latest news</p>
    <NewsSection />
  </StyledNews>
);

const StyledNews = styled.section`
  .hl {
    text-align: right;
    padding-top: var(--sp-5);
    padding-right: var(--sp-1-8);
    padding-bottom: var(--sp-5);

    @media ${media.M} {
      padding-right: calc(var(--sidebar-width-at-m) - 3rem);
    }
  }

  .btn-wrapper {
    display: flex;
    justify-content: center;
    padding-top: var(--sp-3);
  }
`;

export default News;
